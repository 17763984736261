import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { FiArrowRight, FiMail, FiMapPin } from "react-icons/fi";
import { SiGithub, SiTiktok, SiYoutube } from "react-icons/si";
import { CiLinkedin } from "react-icons/ci";

export const RevealBento = () => {
  return (
    <div className="min-h-screen px-4 py-12 text-zinc-50">
      <div />
      <motion.div
        initial="initial"
        animate="animate"
        transition={{
          staggerChildren: 0.05,
        }}
        className="mx-auto grid max-w-4xl grid-flow-dense grid-cols-12 gap-4"
      >
        <HeaderBlock />
        <SocialsBlock />
        <AboutBlock />
      </motion.div>
    </div>
  );
};

const Block = ({ className, ...rest }) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0,
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1,
        },
      }}
      transition={{
        type: "spring",
        mass: 3,
        stiffness: 400,
        damping: 50,
      }}
      className={twMerge(
        "col-span-4 rounded-lg border border-zinc-700 bg-zinc-800 p-6",
        className
      )}
      {...rest}
    />
  );
};

const HeaderBlock = () => (
  <Block className="col-span-12 row-span-2 md:col-span-6"
  whileHover={{
    rotate: "-2.5deg",
    scale: 1.1,
  }}
  >
    <h1 className="mb-12 text-4xl font-medium leading-tight">
      Hi, I'm Jhio.{" "}
      <span className="text-zinc-400">
      A Software Developer. Let’s turn your bold ideas into reality with creativity and code!
      </span>
    </h1>
    <a
      href="https://drive.google.com/file/d/1nvfyQ0xHHTzpdxXdoUeUqkk71cMKMZbh/view"
      className="flex items-center gap-1 text-red-300 hover:underline"
    >
      resume <FiArrowRight />
    </a>
  </Block>
);

const SocialsBlock = () => (
  <>
    <Block
      whileHover={{
        rotate: "-2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-purple-600 md:col-span-3"
    >
      <a
        href="https://github.com/Jhio0"
        className="grid h-full place-content-center text-3xl text-white"
      >
        <SiGithub />
      </a>
    </Block>
   <Block
      whileHover={{
        rotate: "2.5deg",
        scale: 1.1,
      }}
      className="col-span-6 bg-blue-500 md:col-span-3"
    >
      <a
        href="https://www.linkedin.com/in/jhio-soriano-248b15254/"
        className="grid h-full place-content-center text-3xl text-white"
      >
        <CiLinkedin />
      </a>
    </Block>
    <Block
      whileHover={{
        rotate: "2.5deg",
        scale: 1.1,
      }}
      className="col-span-12 md:col-span-6"
    >
      <a
        href="#"
        className="grid h-full place-content-center text-3xl text-white"
      >
        <motion.video loop autoPlay muted playsInline className="w-full mt-6 lg:mt-8">
        <source src="/assets/tumblr_91f22c08ef66bf0c588b735806a11129_fccca83e_540-ezgif.com-resize-video (1).mp4" type="video/mp4" />
      </motion.video>
      </a>
    </Block>

  </>
);

const AboutBlock = () => (
  <Block className="col-span-12 text-3xl leading-snug"
  whileHover={{
    rotate: "-2.5deg",
    scale: 1.1,
  }}
  >
    <p>
    Motivated and detail-oriented Junior Developer.{" "}
      <span className="text-zinc-400">
      skilled in front-end technologies like HTML, CSS, JavaScript, and React,
      with additional expertise in backend development using Python and Java. Passionate about creating dynamic user
      experiences through innovative problem-solving, efficient debugging, and code optimization. Adept at collaborating on
      projects to deliver high-quality, scalable solutions while advancing technical knowledge and career growth..
      </span>
    </p>
  </Block>
);

const LocationBlock = () => (
  <Block className="col-span-12 flex flex-col items-center gap-4 md:col-span-3">
    <FiMapPin className="text-3xl" />
    <p className="text-center text-lg text-zinc-400">Cyberspace</p>
  </Block>
);

const EmailListBlock = () => (
  <Block className="col-span-12 md:col-span-9">
    <p className="mb-3 text-lg">Join my mailing list</p>
    <form
      onSubmit={(e) => e.preventDefault()}
      className="flex items-center gap-2"
    >
      <input
        type="email"
        placeholder="Enter your email"
        className="w-full rounded border border-zinc-700 bg-zinc-800 px-3 py-1.5 transition-colors focus:border-red-300 focus:outline-0"
      />
      <button
        type="submit"
        className="flex items-center gap-2 whitespace-nowrap rounded bg-zinc-50 px-3 py-2 text-sm font-medium text-zinc-900 transition-colors hover:bg-zinc-300"
      >
        <FiMail /> Join the list
      </button>
    </form>
  </Block>
);

const Logo = () => {
  // Temp logo from https://logoipsum.com/
  return (
    <svg
      width="40"
      height="auto"
      viewBox="0 0 50 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto mb-12 fill-zinc-50"
    >
      <path
        d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
        stopColor="#000000"
      ></path>
      <path
        d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
        stopColor="#000000"
      ></path>
    </svg>
  );
};

