import React, {useRef, useEffect} from 'react';
import {motion, useInView} from "framer-motion"
import { riseWithFade } from '../motion/animations.tsx';
import { twMerge } from "tailwind-merge";

import ProjectTiltCard from '../motion/ProjectTiltCard';
import project1Image from "../Images/TopPageImage.jpg";
import PilotImage from "../Images/Pilot.png";
import AutograderImage from "../Images/Autograder.png";
import CarbonImage from "../Images/img1.png";
import StylesImage from "../Images/styles.png";
import { FiArrowRight} from "react-icons/fi";
import { CiShare1 } from "react-icons/ci";

function Projects() {
    const ref = useRef(null);
    const isInView = useInView(ref);

    useEffect(() => {
        console.log("Is in view -> ", isInView);
    }, [isInView]);

    const Block = ({ className, ...rest }) => {
        return (
          <motion.div
            variants={{
              initial: {
                scale: 0.5,
                y: 50,
                opacity: 0,
              },
              animate: {
                scale: 1,
                y: 0,
                opacity: 1,
              },
            }}
            transition={{
              type: "spring",
              mass: 3,
              stiffness: 400,
              damping: 50,
            }}
            className={twMerge(
              "col-span-4 rounded-lg border border-zinc-700 bg-zinc-800 p-6",
              className
            )}
            {...rest}
          />
        );
    };

    const projectDetails = [
        {
            id: 1,
            title: "AutoGrader SQL",
            description: "The Autograder SQL project, part of the Sunsab Project, is a platform designed to help users improve their SQL skills. Built with Spring Boot, Next.js, and PostgreSQL, it allows users to submit SQL queries, which are evaluated against predefined outputs. The system provides real-time feedback, including scores and insights for improvement. The platform is user-friendly, responsive, and designed for seamless interaction across devices. With Spring Boot handling the backend, Next.js for the frontend, and PostgreSQL managing the database, the Autograder SQL project effectively supports learning and skill enhancement in SQL.",
            image: AutograderImage,
            href:"https://github.com/Jhio0/Autograder2.0",
        },
        {
            id: 2,
            title: "Plane Text",
            description: "Developed as a capstone project, the Plane Text mobile app uses .NET MAUI, Flask, and MySQL to streamline pilot schedule management. It features a user-friendly interface for Android and iOS, automates schedule tracking, and provides real-time updates. The app integrates a Flask backend for authentication and data synchronization, with MySQL ensuring efficient data management. Explore the project further on GitHub.",
            image: PilotImage,
            href:"https://github.com/Jhio0/LynxAirlineScheduler",
        },
        {
            id: 3,
            title: "Carbon Emission Tracker",
            description: "I developed a website for tracking carbon emissions, emphasizing secure authentication and data visualization to help users monitor their environmental impact. I also built a carbon emission calculator that allows users to estimate and track their carbon footprint, providing valuable insights into environmental sustainability.",
            image: CarbonImage,
            href:"https://github.com/Jhio0/CarbonFootPrint_Capstone",
        },
        {
            id: 4,
            title: "Stlyes Boutique",
            description: "I created Styles Boutique, an e-commerce web application using Next.js, React, Tailwind CSS, and Redux. The platform offers features such as product browsing, search functionality, cart management, and secure authentication, with seamless integration of Next.js and MongoDB for a robust backend.",
            image: StylesImage,
            href: "https://styles-boutique-ds2m9w90u-jhio0.vercel.app/"
        },
        // Add more projects as needed
    ];

    const ProjectListBlock = ({ description, title, link }) => (
        <Block className=""
        >
            <h2 className='text-white text-lg lg:text-2xl'>{title}</h2>
            <p className="mb-3 text-lg text-white">
                {description}
            </p>
            <a
                href={link}
                className="flex items-center gap-1 text-red-300 hover:underline"
                >
                Link <FiArrowRight />
            </a>
        </Block>
    );

    return (
        <div className='min-h-screen p-10' id='project'>
            <div className='flex justify-center'>
                <motion.h1
                    ref={ref}
                    className="py-20 text-grey-700 text-3xl md:text-2xl lg:text-5xl text-white"
                    initial="hidden"
                    animate={isInView ? "animate" : "initial"}
                    variants={riseWithFade}
                    transition={{ duration: 0.8 }}
                >
                    My <span className="font-bold">Projects</span>
                </motion.h1>
            </div>
            <div className='container h-full'>
                {projectDetails.map((project, index) => (
                    <div key={index} className={`row mb-10 ml-5 ${index % 2 !== 0 ? 'flex-row-reverse' : ''}`}>
                        <div className='col-12 col-md-6 px-2 py-2'>
                            <ProjectTiltCard imageSrc={project.image} />
                        </div>
                        <div className='col-12 col-md-6 px-2 py-2'>
                            <ProjectListBlock description={project.description} title={project.title} link={project.href} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Projects;
